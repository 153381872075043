import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import { v4 as uuidv4 } from 'uuid';

const RedirectToExternal = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

const Routing = () => {
  const uniqueId = uuidv4();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/seeAll" exact element={<SeeAll />} />
        <Route path="/daily" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-24-No-23410220000027476-web?trxId=${uniqueId}`} />} />

        <Route path="/weekly" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-168-No-23410220000027477-web?trxId=${uniqueId}`} />} />

        <Route path="/monthly" exact element={<RedirectToExternal url={`http://ng-app.com/H2nCommunications/Jangukids-720-No-23410220000027478-web?trxId=${uniqueId}`} />} />


        <Route path="*" exact element={<PageNotFound />} />
        <Route path="/error" exact element={<ErrorPage />} />

    
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
